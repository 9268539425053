import React from 'react'

import { LoadingDots } from 'components/shared/Loading'
import Button from 'components/shared/Button'
import Card from 'components/shared/Card'
import {
  Alert,
  FormInput,
  FormContainer,
  Uploader,
  FormRow,
  FormCol,
} from 'components/shared/Forms'

const INITIAL_STATE = {
  error: '',
  loading: false,
  user_img: '',
}

export default class AddUserPhotoForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  onSubmit = event => {
    event.preventDefault()
    this.setState({ loading: true })
    const { user_img } = this.state
    ;(async () => {
      let error = await this.props.onSubmit(user_img)
      if (error) {
        this.setState({ error, loading: false })
      }
    })()
  }

  render() {
    const { user_img, loading } = this.state

    const isInvalid = user_img == ''
    return (
      <FormContainer>
        <Card title="Add a profile picture">
          <form onSubmit={this.onSubmit}>
            This helps lenders and renters know that you are a real person
            <FormRow>
              <FormCol
                style={{
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  marginTop: 20,
                  marginBottom: 10,
                }}
              >
                {user_img && <img src={user_img} height="100" width="100" />}
                <Uploader
                  id="file"
                  name="file"
                  onUploadComplete={info =>
                    this.setState({ user_img: info.cdnUrl })
                  }
                />
              </FormCol>
            </FormRow>
            <LoadingDots loading={loading} />
            <Button big full disabled={isInvalid}>
              Continue
            </Button>
          </form>
        </Card>
      </FormContainer>
    )
  }
}
