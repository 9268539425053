import React from 'react'
import { navigate } from 'gatsby'

import * as routes from 'constants/routes'
import AddUserPhotoForm from 'components/Auth/SignUp/AddUserPhoto'
import Layout from 'layouts/AuthenticatedPage'
import SEO from 'components/shared/SEO'
import { DatabaseService, AuthService } from 'services'

export class AddPhotoPage extends React.Component {
  getRedirectURL = () => {
    let state,
      redirectURL = routes.SEARCH
    if ((state = this.props.location.state)) {
      redirectURL = state.redirectURL || redirectURL
    }
    return redirectURL
  }

  onSubmit = async user_img => {
    try {
      await AuthService.updateProfilePicture({ photoURL: user_img })
      await DatabaseService.addUserPhoto(user_img)

      navigate(this.getRedirectURL(), {
        replace: true,
        state: { ...(this.props.location.state || {}) },
      })
    } catch (error) {
      return error
    }
  }

  render() {
    return <AddUserPhotoForm onSubmit={this.onSubmit} />
  }
}

export default ({ location }) => (
  <Layout location={location} displayFooter={false} showBeacon={true}>
    <SEO title="Add photo" />
    <AddPhotoPage />
  </Layout>
)
